<template>
  <Page title='司法鉴定'>
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">司法鉴定</h1>
          <p class="desc">
            司法鉴定是指在诉讼活动中鉴定人运用科学技术或者专门知识对诉讼涉及的专门性问题进行鉴定和判断并提供鉴定意见的活动，是现代司法活动中广泛使用的一种司法证明方法。随着依法治国方略的深入实施和我国法治化进程的全面推进。
          </p>
          <div class="business-apply-button mt-5">
            <a-button class="mr-3"><router-link to="/appraisal" target="_blank">预约办理</router-link></a-button>
          </div>
          <span class="business-banner-head-icon sfjd-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
        <div class="subtitle">
          <h2>司法鉴定申请指南</h2>
          <hr class="underline" />
        </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="鉴定指引">
            <div class="ov_text">
              <div class="law_methods_main_text">
                <p>什么是法医临床鉴定？</p>
                <p>法医临床鉴定，是指运用法医临床学的理论和技术，对涉及与法律有关的医学问题进行鉴定和评定。其主要内容包括：人身损伤程度鉴定、损伤与疾病关系评定、道路交通事故受伤人员伤残程度评定、职工工伤与职业病致残程度评定、劳动能力评定、活体年龄鉴定、性功能鉴定、诈病（伤）及造作病（伤）鉴定、致伤物和致伤方式推断等。</p>
                <p>什么是法医病理鉴定？</p>
                <p>法医病理鉴定，是指运用法医病理学的理论和技术，通过尸体外表检查、尸体解剖检验、组织切片观察、毒物分析和书证审查等，对涉及与法律有关的医学问题进行鉴定或推断。其主要内容包括：死亡原因鉴定、死亡方式鉴定、死亡时间推断、致伤（死）物认定、生前伤与死后伤鉴别、死后个体识别等。</p>
                <p>什么是法医精神病鉴定？</p>
                <p>法医精神病鉴定，是指运用司法精神病学的理论和方法，对涉及与法律有关的精神状态、法定能力（如刑事责任能力、受审能力、服刑能力、民事行为能力、监护能力、被害人自我防卫能力、作证能力等）、精神损伤程度、智能障碍等问题进行鉴定并提供鉴定结论的活动。</p>
                <p>什么是法医物证鉴定？</p>
                <p>法医物证鉴定，是指运用免疫学、生物学、生物化学、分子生物学等理论和方法，利用遗传学标记系统的多态性对生物学检材的种类、种属及个体来源进行鉴定。其主要内容包括：个体识别、亲子鉴定、种族和种属认定等。</p>
                <p>什么是法医毒物鉴定？</p>
                <p>法医毒物鉴定，是指运用法医毒物学的理论和方法，结合现代仪器分析技术，对体内外未知毒（药）物、毒品及代谢物进行定性、定量分析，并通过对毒物毒性、中毒机理、代谢功能的分析，结合中毒表现、尸检所见，综合作出毒（药）物中毒的鉴定。</p>
                <p>什么是文书鉴定？</p>
                <p>文书鉴定，是指运用文件检验学的原理和技术，对案件中设计的文书的笔迹、印章印文、文书的制作及工具、文书形成时间等有关问题进行分析、鉴别、认定等活动。</p>
                <p>什么是痕迹鉴定？</p>
                <p>痕迹鉴定，是指运用痕迹学的原理和技术，对有关人体、物体形成痕迹的同一性及分离痕迹与原整体相关性等问题进行鉴定。运用枪械学、弹药学、弹道学的理论和技术，对枪弹及射击后的残留物、残留物形成的痕迹、自制枪支和弹药及杀伤力进行鉴定并提供鉴定结论的活动。</p>
                <p>什么是微量鉴定？</p>
                <p>微量鉴定，是指运用物理学、化学和仪器分析等方法，通过对有关物质材料的成分及其结构进行定性、定量分析，对检材的种类、检材和嫌疑样本的同类性和同一性进行鉴定并提供鉴定结论的活动。</p>
                <p>什么是声像资料鉴定？</p>
                <p>声像资料鉴定，是指运用物理学和计算机学的原理和技术，对录音带、录像带、磁盘、光盘、图片等载体上记录的声音、图像信息的真实性、完整性及其所反映的情况过程进行鉴定；并对记录的声音、图像中的语言、人体、物体作出种类或同一认定。</p>
                <p>什么是计算机司法鉴定？</p>
                <p>计算机司法鉴定，是指运用计算机理论和技术，对通过非法手段使计算机系统内数据的安全性、完整性或系统正常运行造成的危害行为及其程度等进行鉴定并提供鉴定结论的活动。</p>
                <p>什么是司法会计鉴定？</p>
                <p>司法会计鉴定，是指运用会计学的原理和方法，通过检查、计算、验证和监证对会计凭证、会计账簿、会计报表和其它会计资料等财务状况等进行鉴定并提供鉴定结论的活动。</p>
                <p>什么是知识产权司法鉴定？</p>
                <p>知识产权司法鉴定，是指根据技术专家对本领域公知技术及相关专业技术的了解，运用必要的检测、化验、分析手段，对被侵权的技术和相关技术的特征是否相同或者等同进行认定；对技术转让合同标的是否成熟、实用，是否符合合同约定标准进行认定；对技术开发合同履行失败是否属于风险责任进行认定；对技术咨询、技术服务以及其他各种技术合同履行结果是否符合合同约定，或者有关法定标准进行认定；对技术秘密是否构成法定技术条件进行认定；对其他知识产权诉讼中的技术争议进行鉴定。</p>
                <p>什么是建筑工程质量鉴定？</p>
                <p>建筑工程质量鉴定是指运用建筑学理论和技术，对与建筑工程相关的问题进行鉴定并提供鉴定结论的活动，其主要内容包括：建筑工程质量评定、工程质量事故鉴定等。</p>
                <p>什么是建筑工程造价鉴定？</p>
                <p>建筑工程造价鉴定是指依据国家的法律、法规以及中央和省、自治区及直辖市等地方政府颁布的工程造价定额标准，针对某一特定建设项目的施工图纸及竣工资料来计算和确定某一工程价值并提供鉴定结论的活动。</p>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="鉴定流程">
            <div class="ov_text"><img src="../../assets/images/sfjd_03.jpg"></div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="技术规范">
            <div class="ov_text">
              <div class="law_methods_main_text jsgf">
                <p><strong>总类</strong></p>
                <a target="_blank" href="1.总类/001+司法鉴定行业标准体系（SFT 0061—2020）.pdf">
                <p>司法鉴定行业标准体系（SFT 0061—2020）</p>
                </a> <a target="_blank" href="1.总类/002+司法鉴定技术规范编写规则（SFT 0062—2020）.pdf">
                <p>司法鉴定技术规范编写规则（SFT 0062—2020）</p>
                </a>
                <p><strong>法医病理鉴定</strong></p>
                <a target="_blank" href="2.法医病理鉴定/001_法医病理_《法医学尸体解剖规范》（SFZ JD0101002-2015）2015-11-20：发布实施.pdf">
                <p>法医病理_《法医学尸体解剖规范》（SFZ JD0101002-2015）</p>
                </a> <a target="_blank" href="2.法医病理鉴定/002_法医病理_《法医学虚拟解剖操作规程》（SFZ JD0101003-2015）2015-11-20：发布实施.pdf">
                <p>法医病理_《法医学虚拟解剖操作规程》（SFZ JD0101003-2015）</p>
                </a> <a target="_blank" href="2.法医病理鉴定/007+尸体多层螺旋计算机体层成像（MSCT）血管造影操作规程（SFT 0067—2020）.pdf">
                <p>尸体多层螺旋计算机体层成像（MSCT）血管造影操作规程（SFT 0067—2020）</p>
                </a>
                <p><strong>法医临床鉴定</strong></p>
                <a target="_blank" href="3.法医临床鉴定/004_法医临床_《法医临床检验规范》（SFZ JD0103003-2011）2011-3-17：发布实施.pdf">
                <p>法医临床_《法医临床检验规范》（SFZ JD0103003-2011）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/005_法医临床_《嗅觉障碍的法医学评定》（SFZ JD0103012-2018）2018-11-8：发布2019-1-1：实施.pdf">
                <p>法医临床_《嗅觉障碍的法医学评定》（SFZ JD0103012-2018）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/006_法医临床_《人体前庭、平衡功能检查评定规范》（SFZ JD0103009-2018）2018-11-8发布2019-1-1实施.pdf">
                <p>法医临床_《人体前庭、平衡功能检查评定规范》（SFZ JD0103009-2018）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/007_法医临床_《外伤性癫痫鉴定实施规范》（SFZ JD0103007-2014）2014-3-17：发布实施.pdf">
                <p>法医临床_《外伤性癫痫鉴定实施规范》（SFZ JD0103007-2014）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/008_法医临床_《周围神经损伤鉴定实施规范》（SFZ JD0103005-2014）2014-3-17：发布实施.pdf">
                <p>法医临床_《周围神经损伤鉴定实施规范》（SFZ JD0103005-2014）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/009_法医临床_《视觉功能障碍法医学鉴定规范》（SFZ JD0103004-2016）2016-9-22：发布实施.pdf">
                <p>法医临床_《视觉功能障碍法医学鉴定规范》（SFZ JD0103004-2016）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/010_法医临床_《法医临床学视觉电生理检查规范》（SFZ JD0103010-2018）2018-11-8发布；2019-1-1实施.pdf">
                <p>法医临床_《法医临床学视觉电生理检查规范》（SFZ JD0103010-2018）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/011_法医临床_《听力障碍法医学鉴定规范》（SFZ JD0103001-2010）2010-4-7：发布实施.pdf">
                <p>法医临床_《听力障碍法医学鉴定规范》（SFZ JD0103001-2010）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/012_法医临床_《男性性功能障碍法医学鉴定》（GBT 37237-2018）2018-12-28发布；2019-4-1实施.pdf">
                <p>法医临床_《男性性功能障碍法医学鉴定》（GBT 37237-2018）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/013_法医临床_《男性生育功能障碍法医学鉴定》（SFZ JD0103011-2018）2018-11-8发布；2019-1-1实施.pdf">
                <p>法医临床_《男性生育功能障碍法医学鉴定》（SFZ JD0103011-2018）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/014_法医临床_《法医临床影像学检验实施规范》（SFZ JD0103006-2014）2014-3-17：发布实施.pdf">
                <p>法医临床_《法医临床影像学检验实施规范》（SFZ JD0103006-2014）</p>
                </a> <a target="_blank" href="3.法医临床鉴定/015_法医临床_《人身损害后续诊疗项目评定指南》（SFZ JD0103008-2015）2015-11-20：发布实施.pdf">
                <p>法医临床_《人身损害后续诊疗项目评定指南》（SFZ JD0103008-2015）</p>
                </a>
                <p><strong>法医物证鉴定</strong></p>
                <a target="_blank" href="4.法医物证鉴定/009+法医物证鉴定实验室管理规范（SFT 0069—2020）.pdf">
                <p>法医物证鉴定实验室管理规范（SFT 0069—2020）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/010+染色体遗传标记高通量测序与法医学应用规范（SFT 0070—2020）.pdf">
                <p>染色体遗传标记高通量测序与法医学应用规范（SFT 0070—2020）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/022_法医物证_《个体识别技术规范》（SFZ JD0105012-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《个体识别技术规范》（SFZ JD0105012-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/023_法医物证_《法医学 STR 基因座命名规范》（SFZ JD0105011-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《法医学 STR 基因座命名规范》（SFZ JD0105011-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/024_法医物证_《常染色体 STR 基因座的法医学参数计算规范》（SFZ JD0105010-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《常染色体 STR 基因座的法医学参数计算规范》（SFZ JD0105010-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/025_法医物证_《法医SNP分型与应用规范》（SFZ JD0105003-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医物证_《法医SNP分型与应用规范》（SFZ JD0105003-2015）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/026_法医物证_《法医物证鉴定 X-STR 检验规范》（SFZ JD0105006-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《法医物证鉴定 X-STR 检验规范》（SFZ JD0105006-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/027_法医物证_《法医物证鉴定 Y-STR 检验规范》（SFZ JD0105007-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《法医物证鉴定 Y-STR 检验规范》（SFZ JD0105007-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/028_法医物证_《法医物证鉴定线粒体 DNA 检验规范》（SFZ JD0105008-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《法医物证鉴定线粒体 DNA 检验规范》（SFZ JD0105008-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/029_法医物证_《法医物证鉴定标准品 DNA 使用与管理规范》（SFZ JD0105009-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医物证_《法医物证鉴定标准品 DNA 使用与管理规范》（SFZ JD0105009-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/030_法医物证_《亲权鉴定技术规范》（GBT 37223-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>法医物证_《亲权鉴定技术规范》（GBT 37223-2018）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/031_法医物证_《亲子鉴定文书规范》（SFZ JD0105004-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医物证_《亲子鉴定文书规范》（SFZ JD0105004-2015）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/032_法医物证_《生物学全同胞关系鉴定实施规范》（SFZ JD0105002-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>法医物证_《生物学全同胞关系鉴定实施规范》（SFZ JD0105002-2014）</p>
                </a> <a target="_blank" href="4.法医物证鉴定/033_法医物证_《生物学祖孙关系鉴定规范》（SFZ JD0105005-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医物证_《生物学祖孙关系鉴定规范》（SFZ JD0105005-2015）</p>
                </a>
                <p><strong>法医精神病鉴定</strong></p>
                <a target="_blank" href="5.法医精神病鉴定/011+精神障碍者性自我防卫能力评定指南（SFT 0071—2020）.pdf">
                <p>精神障碍者性自我防卫能力评定指南（SFT 0071—2020）</p>
                </a> <a target="_blank" href="5.法医精神病鉴定/016_法医精神病_《精神障碍者司法鉴定精神检查规范》（SFZ JD0104001-2011）2011317：发布实施.pdf">
                <p>法医精神病_《精神障碍者司法鉴定精神检查规范》（SFZ JD0104001-2011）</p>
                </a> <a target="_blank" href="5.法医精神病鉴定/017_法医精神病_《精神障碍者刑事责任能力评定指南》（SFZ JD0104002-2016）2016922：发布实施.pdf">
                <p>法医精神病_《精神障碍者刑事责任能力评定指南》（SFZ JD0104002-2016）</p>
                </a> <a target="_blank" href="5.法医精神病鉴定/018_法医精神病_《精神障碍者受审能力评定指南》（SFZ JD0104005-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医精神病_《精神障碍者受审能力评定指南》（SFZ JD0104005-2018）</p>
                </a> <a target="_blank" href="5.法医精神病鉴定/019_法医精神病_《精神障碍者服刑能力评定指南》（SFZ JD0104003-2016）2016-09-22发布；2016-09-22实施.pdf">
                <p>法医精神病_《精神障碍者服刑能力评定指南》（SFZ JD0104003-2016）</p>
                </a> <a target="_blank" href="5.法医精神病鉴定/020_法医精神病_《精神障碍者民事行为能力评定指南》（SFZ JD0104004-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医精神病_《精神障碍者民事行为能力评定指南》（SFZ JD0104004-2018）</p>
                </a>
                <p><strong>法医毒物鉴定</strong></p>
                <a target="_blank" href="6.法医毒物鉴定/003+法医毒物分析方法验证通则（SFT 0063—2020）.pdf">
                <p>法医毒物分析方法验证通则（SFT 0063—2020）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/004+血液中188种毒（药）物的气相色谱-高分辨质谱检验方法（SFT 0064—2020）.pdf">
                <p>血液中188种毒（药）物的气相色谱-高分辨质谱检验方法（SFT 0064—2020）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/005+毛发中二甲基色胺等16种色胺类新精神活性物质及其代谢物的液相色谱-串联质谱检验方法（SFT 0065—2020）.pdf">
                <p>毛发中二甲基色胺等16种色胺类新精神活性物质及其代谢物的液相色谱-串联质谱检验方法（SFT 0065—2020）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/006+生物检材中芬太尼等31种芬太尼类新精神活性物质及其代谢物的液相色谱-串联质谱检验方法（SFT 0066—2020）.pdf">
                <p>生物检材中芬太尼等31种芬太尼类新精神活性物质及其代谢物的液相色谱-串联质谱检验方法（SFT 0066—2020）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/034_法医毒物_《血液中氰化物的测定气相色谱法》（SFZ JD0107002-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>法医毒物_《血液中氰化物的测定气相色谱法》（SFZ JD0107002-2010）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/035_法医毒物_《血液、尿液中毒鼠强的测定气相色谱法》（SFZ JD0107003-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>法医毒物_《血液、尿液中毒鼠强的测定气相色谱法》（SFZ JD0107003-2010）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/036_法医毒物_《血液中碳氧血红蛋白饱和度的测定分光光度法》（SFZ JD0107010-2011）2011-03-17发布；2011-03-17实施.pdf">
                <p>法医毒物_《血液中碳氧血红蛋白饱和度的测定分光光度法》（SFZ JD0107010-2011）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/037_法医毒物_《法医毒物有机质谱定性分析通则》（SFZ JD0107019-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《法医毒物有机质谱定性分析通则》（SFZ JD0107019-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/038_法医毒物_《血液中铬、镉、砷、铊和铅的测定 电感耦合等离子体质谱法》（SFZ JD0107012-2011）2011-03-17发布；2011-03-17实施.pdf">
                <p>法医毒物_《血液中铬、镉、砷、铊和铅的测定 电感耦合等离子体质谱法》（SFZ JD0107012-2011）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/039_法医毒物_《气相色谱-质谱联用法测定硫化氢中毒血液中的硫化物实施规范》（SFZ JD0107013-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>法医毒物_《气相色谱-质谱联用法测定硫化氢中毒血液中的硫化物实施规范》（SFZ JD0107013-2014）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/040_法医毒物_《血液中45种有毒生物碱成分的液相色谱-串联质谱检验方法》（SFZ JD0107015-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医毒物_《血液中45种有毒生物碱成分的液相色谱-串联质谱检验方法》（SFZ JD0107015-2015）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/041_法医毒物_《血液中溴敌隆等 13 种抗凝血类杀鼠药的液相色谱-串联质谱检验方法》（SFZ JD0107018-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《血液中溴敌隆等 13 种抗凝血类杀鼠药的液相色谱-串联质谱检验方法》（SFZ JD0107018-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/042_法医毒物_《血液中磷化氢及其代谢物的顶空气相色谱 -质谱检验方法》（SFZ JD0107020-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《血液中磷化氢及其代谢物的顶空气相色谱 -质谱检验方法》（SFZ JD0107020-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/043_法医毒物_《血液、尿液中238种毒(药)物的检测 液相色谱-串联质谱法》（SFZ JD0107005-2016）2016-09-22发布；2016-09-22实施.pdf">
                <p>法医毒物_《血液、尿液中238种毒(药)物的检测 液相色谱-串联质谱法》（SFZ JD0107005-2016）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/044_法医毒物_《血液和尿液中108种毒（药）物的气相色谱-质谱检验方法》（SFZ JD0107014-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医毒物_《血液和尿液中108种毒（药）物的气相色谱-质谱检验方法》（SFZ JD0107014-2015）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/045_法医毒物_《尿液中△9-四氢大麻酸的测定 液相色谱-串联质谱法》（GBT 37272-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>法医毒物_《尿液中△9-四氢大麻酸的测定 液相色谱-串联质谱法》（GBT 37272-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/046_法医毒物_《尿液、毛发中 S(+)-甲基苯丙胺、R(-)-甲 基苯丙胺、S(+)-苯丙胺和 R(-)-苯丙胺的 液相色谱-串联质谱检验方法》（SFZ JD0107024-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《尿液、毛发中 S(+)-甲基苯丙胺、R(-)-甲 基苯丙胺、S(+)-苯丙胺和 R(-)-苯丙胺的 液相色谱-串联质谱检验方法》（SFZ JD0107024-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/047_法医毒物_《毛发中15种毒品及代谢物的液相色谱-串联质谱检验方法》（SFZ JD0107025-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《毛发中15种毒品及代谢物的液相色谱-串联质谱检验方法》（SFZ JD0107025-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/048_法医毒物_《毛发中可卡因及其代谢物苯甲酰爱康宁的液相色谱-串联质谱检验方法》（SFZ JD0107016-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医毒物_《毛发中可卡因及其代谢物苯甲酰爱康宁的液相色谱-串联质谱检验方法》（SFZ JD0107016-2015）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/049_法医毒物_《毛发中D 9 -四氢大麻酚、大麻二酚和大麻酚 的液相色谱-串联质谱检验方法》（SFZ JD0107022-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《毛发中D 9 -四氢大麻酚、大麻二酚和大麻酚 的液相色谱-串联质谱检验方法》（SFZ JD0107022-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/050_法医毒物_《生物检材中32种元素的测定 电感耦合等离子体质谱法》（SFZ JD0107017-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>法医毒物_《生物检材中32种元素的测定 电感耦合等离子体质谱法》（SFZ JD0107017-2015）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/051_法医毒物_《生物检材中苯丙胺类兴奋剂、哌替啶和氯胺酮的测定》（SFZ JD0107004-2016）2016-09-22发布；2016-09-22实施.pdf">
                <p>法医毒物_《生物检材中苯丙胺类兴奋剂、哌替啶和氯胺酮的测定》（SFZ JD0107004-2016）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/052_法医毒物_《生物检材中单乙酰吗啡、吗啡、可待因的测定》（SFZ JD0107006-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>法医毒物_《生物检材中单乙酰吗啡、吗啡、可待因的测定》（SFZ JD0107006-2010）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/053_法医毒物_《生物检材中巴比妥类药物的测定液相色谱-串联质谱法》（SFZ JD0107008-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>法医毒物_《生物检材中巴比妥类药物的测定液相色谱-串联质谱法》（SFZ JD0107008-2010）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/054_法医毒物_《生物检材中乌头碱、新乌头碱和次乌头碱的LCMSMS测定液相色谱-串联质谱法》（SFZ JD0107009-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>法医毒物_《生物检材中乌头碱、新乌头碱和次乌头碱的LCMSMS测定液相色谱-串联质谱法》（SFZ JD0107009-2010）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/055_法医毒物_《生物检材中河豚毒素的测定 液相色谱-串联质谱法》（SFZ JD0107011-2011）2011-03-17发布；2011-03-17实施.pdf">
                <p>法医毒物_《生物检材中河豚毒素的测定 液相色谱-串联质谱法》（SFZ JD0107011-2011）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/056_法医毒物_《生物检材中钩吻素子、钩吻素甲和钩吻素己的液相色谱-串联质谱检验方法》（SFZ JD0107021-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《生物检材中钩吻素子、钩吻素甲和钩吻素己的液相色谱-串联质谱检验方法》（SFZ JD0107021-2018）</p>
                </a> <a target="_blank" href="6.法医毒物鉴定/057_法医毒物_《生物检材中雷公藤甲素和雷公藤酯甲的液 相色谱-串联质谱检验方法》（SFZ JD0107023-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>法医毒物_《生物检材中雷公藤甲素和雷公藤酯甲的液 相色谱-串联质谱检验方法》（SFZ JD0107023-2018）</p>
                </a>
                <p><strong>文书鉴定</strong></p>
                <a target="_blank" href="7.文书鉴定/058_文书鉴定_《文件鉴定通用规范》（GBT 37234-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《文件鉴定通用规范》（GBT 37234-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/059_文书鉴定_《笔迹鉴定技术规范》（GBT 37239-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《笔迹鉴定技术规范》（GBT 37239-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/060_文书鉴定_《印章印文鉴定技术规范》（GBT 37231-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《印章印文鉴定技术规范》（GBT 37231-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/061_文书鉴定_《印刷文件鉴定技术规范》（GBT 37232-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《印刷文件鉴定技术规范》（GBT 37232-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/062_文书鉴定_《文件制作时间鉴定技术规范》（GBT 37233-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《文件制作时间鉴定技术规范》（GBT 37233-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/063_文书鉴定_《篡改（污损）文件鉴定技术规范》（GBT 37238-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《篡改（污损）文件鉴定技术规范》（GBT 37238-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/064_文书鉴定_《特种文件鉴定技术规范》（GBT 37236-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《特种文件鉴定技术规范》（GBT 37236-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/065_文书鉴定_《文件材料鉴定技术规范》（GBT 37235-2018）2018-12-28发布；2019-04-01实施.pdf">
                <p>文书鉴定_《文件材料鉴定技术规范》（GBT 37235-2018）</p>
                </a> <a target="_blank" href="7.文书鉴定/066_文书鉴定_《藏文笔迹鉴定实施规范 第1部分藏文笔迹特征的分类》（SFZ JD0201009.1-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>文书鉴定_《藏文笔迹鉴定实施规范 第1部分藏文笔迹特征的分类》（SFZ JD0201009.1-2014）</p>
                </a> <a target="_blank" href="7.文书鉴定/067_文书鉴定_《藏文笔迹鉴定实施规范 第2部分《藏文笔迹特征比对表》的制作规范》（SFZ JD0201009.2-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>文书鉴定_《藏文笔迹鉴定实施规范 第2部分《藏文笔迹特征比对表》的制作规范》（SFZ JD0201009.2-2014）</p>
                </a> <a target="_blank" href="7.文书鉴定/068_文书鉴定_《藏文笔迹鉴定实施规范 第3部分藏文笔迹鉴定结论的种类及判断依据》（SFZ JD0201009.3-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>文书鉴定_《藏文笔迹鉴定实施规范 第3部分藏文笔迹鉴定结论的种类及判断依据》（SFZ JD0201009.3-2014）</p>
                </a> <a target="_blank" href="7.文书鉴定/069_文书鉴定_《藏文笔迹鉴定实施规范 第4部分藏文笔迹鉴定规程》（SFZ JD0201009.4-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>文书鉴定_《藏文笔迹鉴定实施规范 第4部分藏文笔迹鉴定规程》（SFZ JD0201009.4-2014）</p>
                </a> <a target="_blank" href="7.文书鉴定/070_文书鉴定_《藏文笔迹鉴定实施规范 第5部分：藏文签名鉴定规程》（SFZ JD0201009.5-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>文书鉴定_《藏文笔迹鉴定实施规范 第5部分：藏文签名鉴定规程》（SFZ JD0201009.5-2014）</p>
                </a> <a target="_blank" href="7.文书鉴定/071_文书鉴定_《多光谱视频文件检验仪检验规程》（SFZ JD0201014-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>文书鉴定_《多光谱视频文件检验仪检验规程》（SFZ JD0201014-2015）</p>
                </a>
                <p><strong>痕迹鉴定</strong></p>
                <a target="_blank" href="8.痕迹鉴定/072_痕迹鉴定_《文件上可见指印鉴定技术规范》（SFZ JD0202001-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>痕迹鉴定_《文件上可见指印鉴定技术规范》（SFZ JD0202001-2015）</p>
                </a>
                <p><strong>道路交通事故鉴定</strong></p>
                <a target="_blank" href="9.道路交通事故鉴定/003_《道路交通事故涉案者交通行为方式鉴定》（SFZ JD0101001-2016）2016-9-22：发布实施.pdf">
                <p>《道路交通事故涉案者交通行为方式鉴定》（SFZ JD0101001-2016）</p>
                </a> <a target="_blank" href="9.道路交通事故鉴定/012+道路交通事故痕迹物证鉴定通用规范（SFT 0072—2020）.pdf">
                <p>道路交通事故痕迹物证鉴定通用规范（SFT 0072—2020）</p>
                </a> <a target="_blank" href="9.道路交通事故鉴定/013+基于视频图像的道路交通事故信号灯状态鉴定规范（SFT 0073—2020）.pdf">
                <p>基于视频图像的道路交通事故信号灯状态鉴定规范（SFT 0073—2020）</p>
                </a> <a target="_blank" href="9.道路交通事故鉴定/021_《道路交通事故受伤人员精神伤残评定规范》（SFZ JD0104004-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>《道路交通事故受伤人员精神伤残评定规范》（SFZ JD0104004-2014）</p>
                </a>
                <p><strong>环境损害鉴定</strong></p>
                <a target="_blank" href="10.环境损害鉴定/008+环境损害致人身伤害司法鉴定技术导则（SFT 0068—2020）.pdf">
                <p>环境损害致人身伤害司法鉴定技术导则（SFT 0068—2020）</p>
                </a> <a target="_blank" href="10.环境损害鉴定/014+耕地和林地破坏司法鉴定技术规范（SFT 0074—2020）.pdf">
                <p>耕地和林地破坏司法鉴定技术规范（SFT 0074—2020）</p>
                </a> <a target="_blank" href="10.环境损害鉴定/106_环境损害_《农业环境污染事故司法鉴定经济损失估算实施规范》（SFZ JD0601001-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>环境损害_《农业环境污染事故司法鉴定经济损失估算实施规范》（SFZ JD0601001-2014）</p>
                </a> <a target="_blank" href="10.环境损害鉴定/107_环境损害_《农业环境污染损害司法鉴定操作技术规范》（SFZ JD0606001-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>环境损害_《农业环境污染损害司法鉴定操作技术规范》（SFZ JD0606001-2018）</p>
                </a> <a target="_blank" href="10.环境损害鉴定/108_环境损害_《农作物污染司法鉴定调查技术规范》（SFZ JD0606002-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>环境损害_《农作物污染司法鉴定调查技术规范》（SFZ JD0606002-2018）</p>
                </a>
                <p><strong>电子数据鉴定</strong></p>
                <a target="_blank" href="11.电子数据鉴定/015+网络文学作品相似性检验技术规范（SFT 0075—2020）.pdf">
                <p>网络文学作品相似性检验技术规范（SFT 0075—2020）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/016+电子数据存证技术规范（SFT 0076—2020）.pdf">
                <p>电子数据存证技术规范（SFT 0076—2020）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/017+汽车电子数据检验技术规范（SFT 0077—2020）.pdf">
                <p>汽车电子数据检验技术规范（SFT 0077—2020）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/093_电子数据_《电子数据司法鉴定通用实施规范》（SFZ JD0400001-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>电子数据_《电子数据司法鉴定通用实施规范》（SFZ JD0400001-2014）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/094_电子数据_《电子证据数据现场获取通用规范》（SFZ JD0400002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>电子数据_《电子证据数据现场获取通用规范》（SFZ JD0400002-2015）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/095_电子数据_《电子数据复制设备鉴定实施规》（SFZ JD0401001-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>电子数据_《电子数据复制设备鉴定实施规》（SFZ JD0401001-2014）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/096_电子数据_《手机电子数据提取操作规范》（SFZ JD0401002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>电子数据_《手机电子数据提取操作规范》（SFZ JD0401002-2015）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/097_电子数据_《电子邮件鉴定实施规范》（SFZ JD0402001-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>电子数据_《电子邮件鉴定实施规范》（SFZ JD0402001-2014）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/098_电子数据_《数据库数据真实性鉴定规范》（SFZ JD0402002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>电子数据_《数据库数据真实性鉴定规范》（SFZ JD0402002-2015）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/099_电子数据_《即时通讯记录检验操作规范》（SFZ JD0402003-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>电子数据_《即时通讯记录检验操作规范》（SFZ JD0402003-2015）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/100_电子数据_《电子文档真实性鉴定技术规范》（SFZ JD0402004-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>电子数据_《电子文档真实性鉴定技术规范》（SFZ JD0402004-2018）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/101_电子数据_《软件相似性鉴定实施规范》（SFZ JD0403001-2014）2014-03-17发布；2014-03-17实施.pdf">
                <p>电子数据_《软件相似性鉴定实施规范》（SFZ JD0403001-2014）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/102_电子数据_《破坏性程序检验操作规范》（SFZ JD0403002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>电子数据_《破坏性程序检验操作规范》（SFZ JD0403002-2015）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/103_电子数据_《计算机系统用户操作行为检验规范》（SFZ JD0403003-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>电子数据_《计算机系统用户操作行为检验规范》（SFZ JD0403003-2015）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/104_电子数据_《软件功能鉴定技术规范》（SFZ JD0403004-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>电子数据_《软件功能鉴定技术规范》（SFZ JD0403004-2018）</p>
                </a> <a target="_blank" href="11.电子数据鉴定/105_电子数据_《伪基站检验操作规范》（SFZ JD0404001-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>电子数据_《伪基站检验操作规范》（SFZ JD0404001-2018）</p>
                </a>
                <p><strong>声像资料鉴定</strong></p>
                <a target="_blank" href="12.声像资料鉴定/018+数字图像元数据检验技术规范（SFT 0078—2020）.pdf">
                <p>数字图像元数据检验技术规范（SFT 0078—2020）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/081_声像资料_《声像资料鉴定通用规范》（SFZ JD0300001-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>声像资料_《声像资料鉴定通用规范》（SFZ JD0300001-2010）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/082_声像资料_《音像制品同源性鉴定技术规范》（SFZ JD0300002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>声像资料_《音像制品同源性鉴定技术规范》（SFZ JD0300002-2015）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/083_声像资料_《数字声像资料提取与固定技术规范》（SFZ JD0300002-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>声像资料_《数字声像资料提取与固定技术规范》（SFZ JD0300002-2018）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/084_声像资料_《录音资料鉴定规范》（SFZ JD0301001-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>声像资料_《录音资料鉴定规范》（SFZ JD0301001-2010）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/085_声像资料_《录音设备鉴定技术规范》（SFZ JD0301002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>声像资料_《录音设备鉴定技术规范》（SFZ JD0301002-2015）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/086_声像资料_《录音资料处理技术规范》（SFZ JD0301003-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>声像资料_《录音资料处理技术规范》（SFZ JD0301003-2015）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/087_声像资料_《图像真实性鉴定技术规范》（SFZ JD0302001-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>声像资料_《图像真实性鉴定技术规范》（SFZ JD0302001-2015）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/088_声像资料_《图像资料处理技术规范》（SFZ JD0302002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>声像资料_《图像资料处理技术规范》（SFZ JD0302002-2015）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/089_声像资料_《数字图像修复技术规范》（SFZ JD0302003-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>声像资料_《数字图像修复技术规范》（SFZ JD0302003-2018）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/090_声像资料_《照相设备鉴定技术规范》（SFZ JD0303001-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>声像资料_《照相设备鉴定技术规范》（SFZ JD0303001-2018）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/091_声像资料_《录像资料鉴定规范》（SFZ JD0304001-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>声像资料_《录像资料鉴定规范》（SFZ JD0304001-2010）</p>
                </a> <a target="_blank" href="12.声像资料鉴定/092_声像资料_《录像设备鉴定技术规范》（SFZ JD0304002-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>声像资料_《录像设备鉴定技术规范》（SFZ JD0304002-2018）</p>
                </a>
                <p><strong>微量物证鉴定</strong></p>
                <a target="_blank" href="13.微量物证鉴定/019+墨迹实时直接分析-高分辨质谱检验技术规范（SFT 0079—2020）.pdf">
                <p>墨迹实时直接分析-高分辨质谱检验技术规范（SFT 0079—2020）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/020+单根纤维的比对检验 激光显微拉曼光谱法（SFT 0080—2020）.pdf">
                <p>单根纤维的比对检验 激光显微拉曼光谱法（SFT 0080—2020）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/073_微量鉴定_《微量物证鉴定通用规范》（SFZ JD0203006-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>微量鉴定_《微量物证鉴定通用规范》（SFZ JD0203006-2018）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/074_微量鉴定_《纤维物证鉴定规范》（SFZ JD0203007-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>微量鉴定_《纤维物证鉴定规范》（SFZ JD0203007-2018）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/075_微量鉴定_《玻璃物证鉴定规范》（SFZ JD0203008-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>微量鉴定_《玻璃物证鉴定规范》（SFZ JD0203008-2018）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/076_微量鉴定_《油漆鉴定规范》（SFZ JD0203001-2010）2010-04-07发布；2010-04-07实施.pdf">
                <p>微量鉴定_《油漆鉴定规范》（SFZ JD0203001-2010）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/077_微量鉴定_《激光显微拉曼光谱法检验墨水》（SFZ JD0203002-2015）2015-11-20发布；2015-11-20实施.pdf">
                <p>微量鉴定_《激光显微拉曼光谱法检验墨水》（SFZ JD0203002-2015）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/078_微量鉴定_《红外光谱法检验墨粉》（SFZ JD0203003-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>微量鉴定_《红外光谱法检验墨粉》（SFZ JD0203003-2018）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/079_微量鉴定_《书写墨迹中 9 种挥发性溶剂的检测 气相色谱-质谱法》（SFZ JD0203004-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>微量鉴定_《书写墨迹中 9 种挥发性溶剂的检测 气相色谱-质谱法》（SFZ JD0203004-2018）</p>
                </a> <a target="_blank" href="13.微量物证鉴定/080_微量鉴定_《书写墨迹中 9 种染料的检测 液相色谱-高分辨质谱法》（SFZ JD0203005-2018）2018-11-08发布；2019-01-01实施.pdf">
                <p>微量鉴定_《书写墨迹中 9 种染料的检测 液相色谱-高分辨质谱法》（SFZ JD0203005-2018）</p>
                </a> </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="file" tab="规范性文件">
            <div class="ov_text">
              <p>1、司法部办公厅《司法鉴定机构鉴定人记录和报告干预司法鉴定活动的有关规定》的通知</p>
              <p>2、司法部关于印发《法医类司法鉴定执业分类规定》的通知</p>
              <p>3、司法部关于进一步规范和完善司法鉴定人出庭作证活动的指导意见</p>
              <p>4、 XX省发改委、省司法厅《XX省司法鉴定收费管理办法》（甘发改规范［2017］4号）</p>
              <p>5、XX高院、省公安厅《2020年XX省道路交通事故人身损害赔偿有关费用计算标准》（甘公交［2020］195号）  （2020赔偿标准！）</p>
              <p>6、XX省司法厅《XX省司法鉴定执业活动投诉处理流程》（2020）   （规范19种制式文书）</p>
              <p>7、XX省公安厅、省司法厅、省财政厅、省卫健委、省扶贫办《关于解决建档立卡贫困户在助产机构外出生的无户口人员开展户口登记的通知》（甘公治安［2019］250号）</p>
              <p>8、XX高院、省公安厅《关于印发2019年XX省道路交通事故人身损害赔偿有关费用计算的通知》（甘公警令发［2019］45号）</p>
              <p>9、XX省司法厅《关于进一步向市州下放司法鉴定管理职权的通知》（甘司发［2018］176号）</p>
              <p>10、XX省司法厅《关于全省司法鉴定机构资质认定和能力验证（2015-2017年度）情况的通报》（甘司发［2018］101号）</p>
              <p>11、XX省司法厅出台司法鉴定严格准入严格监管实施意见</p>
              <p>12、XX省发改委、省司法厅《XX省司法鉴定收费管理办法》（甘发改规范［2017］4号）</p>
              <p>13、XX省司法厅《XX司法鉴定人助理管理办法(试行)》（甘司发［2017］355号）</p>
              <p>14、XX省司法厅《关于认真做好全省精神障碍医学鉴定工作的通知》（2013年9月27日）</p>
              <p>15、XX省司法厅、省档案局《XX省司法鉴定机构鉴定档案管理办法》（甘司办发［2009］128号）</p>
              <p>16、XX高院、省公安厅、省司法厅、XX银保监管局关于印发《XX省道路交通事故损害赔偿顼目计算标准（试行）》《XX省道路交通事故主要情形损害赔偿责任比例（试行）》的通知（甘高法发［2020］3号）</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="money" tab="收费标准">
            <div class="ov_text">
              <p>XX省发展和改革委员会XX省司法厅</p>
              <p>XX省司法鉴定收费管理办法</p>
              <p>（2017年5月26日甘发改规范［2017］4号）</p>
              <p>第一条:为规范司法鉴定收费行为，维护委托人和司法鉴定机构的合法权益，促进司法鉴定服务业的健康有序发展，依据《价格法》《全国人民代表大会常务委员会关于司法鉴定管理问题的决定》和国家发展改革委、教育部、司法部、国家新闻出版广电总局《关于下放教材及部分服务价格定价权限有关问题的通知》（发改价格［2015］1199号）等法规政策，结合我省实际，制定本办法。</p>
              <p>第二条:本办法适用于经省司法行政部门核准登记的司法鉴定机构接受委托人的委托，从事司法鉴定服务并收取服务费的行为。</p>
              <p>第三条:司法鉴定收费应当遵循公开公平、诚实信用、合理有偿的原则。</p>
              <p>第四条:司法鉴定机构应当按照本办法规定收取司法鉴定费以及其他相关费用，并出具合法票据。司法鉴定人不得私自收取任何费用。</p>
              <p>第五条:司法鉴定收费实行政府指导价管理，以省发展改革部门会同省司法行政部门制定的《XX省司法鉴定收费项目与基准价目录》（见附件）规定的执行标准为基础。不得上浮，下浮不限。</p>
              <p>第六条:国家发展改革部门或省发展改革部门对未列入《XX省司法鉴定收费项目与基准价目录》的相关行业的司法鉴定项目和收费另有规定的，从其规定。</p>
              <p>未列入《XX省司法鉴定收费项目与基准价目录》的其他司法鉴定项目的收费实行市场调节价，由司法鉴定机构与委托人协商确定。司法鉴定机构与委托人协商司法鉴定收费应当考虑以下主要因素：</p>
              <p>（一）司法鉴定工作的成本和难易程度；</p>
              <p>（二）委托人的承受能力；</p>
              <p>（三）委托人与司法鉴定机构对鉴定时限的特殊约定；</p>
              <p>（四）司法鉴定机构、司法鉴定人可能承担的风险和责任。</p>
              <p>第七条:对个别疑难、复杂和有重大社会影响的司法鉴定案件，司法鉴定机构在与委托人协商一致的前提下，可在基准价基础上上浮，浮动幅度不超过30%。</p>
              <p>符合以下情形之一的，可以认定为疑难、复杂和有重大社会影响的司法鉴定案件：</p>
              <p>（一）重大涉外案件、危害国家安全案件、恐怖活动案件；</p>
              <p>（二）可能判处无期徒刑、死刑或涉及巨额民事诉讼的案件；</p>
              <p>（三）中级以上人民法院、省（直辖市、自治区）检察院及其分院、省（直辖市、自治区）公安局委托的案件；</p>
              <p>（四）涉及多门学科或需要实验室特殊检验的鉴定案件；</p>
              <p>（五）涉及特殊检材样品检验的案件；</p>
              <p>（六）二次以上（不含二次）鉴定的案件；</p>
              <p>（七）鉴定人出庭质证后仍存有较大争议的鉴定案件。</p>
              <p>第八条:界定是否属于疑难、复杂和有重大社会影响的司法鉴定案件需由司法鉴定机构作出，并在司法鉴定委托书中注明。承担疑难、复杂和有重大社会影响的司法鉴定案件的鉴定人应当具有相关专业的高级职称（相关专业无高级职称评定的除外）。</p>
              <p>司法鉴定机构在受理疑难、复杂和有重大社会影响的司法鉴定案件并按相关规定收取鉴定费用时，应当书面告知委托人并经其确认。</p>
              <p>第九条:司法鉴定中物证类的文书鉴定和痕迹鉴定中的手印鉴定，涉及财产案件的司法鉴定收费，根据诉讼标的和鉴定标的两者中的较小值，按照标的额比例分段累计收取，但收费总额最高不得超过10万元。具体比例如下：</p>
              <p>（一）不超过10万元的，按照本办法附件规定的基准价执行；</p>
              <p>（二）超过10万元至50万元的部分，按照1%收取；</p>
              <p>（三）超过50万元至100万元的部分，按照0.8%收取；</p>
              <p>（四）超过100万元至200万元的部分，按照0.6%收取；</p>
              <p>（五）超过200万元至500万元的部分，按照0.4%收取；</p>
              <p>（六）超过500万元至1000万元的部分，按照0.2%收取；</p>
              <p>（七）超过1000万元的部分，按照0.1%收取。</p>
              <p>第十条:司法鉴定机构决定受理鉴定委托的，应当与委托人签订司法鉴定委托书，并载明鉴定内容、收费方式、收费项目、收费标准、收费金额、结算方式、争议解决办法等条款。没有签订司法鉴定委托书的，司法鉴定机构不得收费。司法鉴定机构未按照司法鉴定委托书规定提供服务的，应当退回相应费用。</p>
              <p>第十一条:司法鉴定机构工作人员到异地实施鉴定、提取鉴定材料发生的交通、住宿、就餐等差旅费，不属于司法鉴定收费范围，由当事人另行支付。</p>
              <p>司法鉴定机构收取前款规定的差旅费，应与委托人协商一致，并在司法鉴定委托书中注明。</p>
              <p>第十二条:司法鉴定机构在实施鉴定过程中单方邀请专家参与鉴定或者提供咨询意见的，所发生的费用由司法鉴定机构自行承担。委托人同意另行支付专家咨询费并在司法鉴定委托书中注明的除外。</p>
              <p>第十三条:司法鉴定机构工作人员在人民法院指定日期出庭作证发生的交通费、住宿费、生活费和误工补贴等必要费用，不属于司法鉴定费用，按国家有关规定执行。</p>
              <p>第十四条:司法鉴定实施过程中因司法鉴定机构原因终止委托关系的，司法鉴定机构应当退还预收的鉴定费；造成委托人损失的，应根据司法鉴定委托书约定或法律规定予以赔偿。</p>
              <p>司法鉴定实施过程中因委托人原因终止鉴定的，根据实际工作量，由委托人和司法鉴定机构协商退还部分或全部鉴定费。</p>
              <p>因不可抗力致使司法鉴定无法继续进行的，司法鉴定机构应对预收的鉴定费按办理鉴定的实际支出进行相应扣除，余额部分退还委托人。</p>
              <p>第十五条:符合法律援助条件的受援人，凭法律援助机构提供的有效证明申请司法鉴定的，司法鉴定机构按照有关规定减收或者免收受援人的司法鉴定费用。</p>
              <p>对于不符合法律援助条件但确有困难的，司法鉴定机构可以酌情减收或者免收相关的司法鉴定费用。</p>
              <p>第十六条:司法鉴定机构应当在执业场所显著位置公示司法鉴定项目、收费标准、投诉举报电话等信息，接受社会监督。</p>
              <p>第十七条:各级发展改革（物价）部门和司法行政部门应当根据工作职能，加强对司法鉴定机构收费行为和提供司法鉴定服务的监督检查，依法查处各种违法违规行为。</p>
              <p>第十八条:本办法自2017年6月1日起施行，有效期5年。《XX省发展改革委、XX省司法厅关于印发&lt;XX省司法鉴定收费管理办法&gt;的通知》（甘发改收费［2013］186号）同时废止。</p>
            </div>
          </a-tab-pane>
        </a-tabs></div>
        <IntelligentConsult :value="appointmentUrl"></IntelligentConsult>
        <div class="mt-5 overflow-hidden tab">
          <div class="col-lg-6 col-md-6 col-xs-6 float-left pl-0">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="服务动态">
                <div  class="tab-pane">
                    <Collection  name="post"   :query="{ target: '0176893f2d9c8a838128768809590a47' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="司鉴动态">
                <div  class="tab-pane">
              <Collection  name="post" :query="{ target: '0176893597778a838128768809590981' }"
                v-slot="{ collection }"  :limit=9 >
              <Article :refObj="collection"></Article>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-6 float-right p-0 pl-4">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="司法鉴定案件">
                <div  class="tab-pane">
              <Collection  name="case" :query="{ recase_type: '司法鉴定' }"
                v-slot="{ collection }"  :limit=9 >
              <Case :refObj="collection"></Case>
              </collection>
              </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="政策法规">
                <div  class="tab-pane">
              <Collection  name="law" :query="{ law_ctype: '司法鉴定' }"
                v-slot="{ collection }"  :limit=9 >
              <Law :refObj="collection"></Law>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>

        <div class="mt-5 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="鉴定机构">
              <div class="screen_box">
                <City :value= providerQuery.provider_type @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  business_type: providerQuery.professional,
                  provider_name: providerQuery.provider_name,
                  provider_type: providerQuery.provider_type
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="鉴定员">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  person_type: '司法鉴定人员'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
        </div>
      </section>
    </article>
  </Page>
</template>

<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import Article from '../../components/base/Article.vue'
import Case from '../../components/base/Case.vue'
import Law from '../../components/base/Law.vue'
import IntelligentConsult from '@/components/business/IntelligentConsult'
export default {
  components: {
    Collection,
    Page,
    Org,
    City,
    Person,
    Article,
    IntelligentConsult,
    Case,
    Law
  },
  data () {
    return {
      appointmentUrl: '/appraisal',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        professional: '',
        provider_name: '',
        person_name: '',
        provider_type: '司法鉴定机构'
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
      this.providerQuery.professional = obj.professional
    }
  }
}
</script>
